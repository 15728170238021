import React from 'react';
import { Container, Row, Col } from '../../reusecore/Layout';
import Box from '../../reusecore/Box';
import Heading from '../../reusecore/Heading';
import Wrapper from './styles';

const currencies = [
  {
    label: 'USDT',
    value: 'USDT',
  },
  {
    label: 'USDC',
    value: 'USDC',
  },
  {
    label: 'BUSD',
    value: 'BUSD',
  },
  {
    label: 'DAI',
    value: 'DAI',
  },
];

export default class Calculator extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      amount: 0,
      currency: 'USDT',
      sendingAmount: 0,
      rate: 100,
    };
  }

  render() {
    const { amount, currency, sendingAmount } = this.state;

    const handleOnChangeAmount = (event) => {
      this.setState({ amount: event.target.value });

      this.setState({ sendingAmount: event.target.value * this.state.rate });
    };

    const handleOnChangeCurrency = (event) => {
      this.setState({ currency: event.target.value });

      this.setState({ sendingAmount: this.state.amount * this.state.rate });
    };

    return (
      <Wrapper>
        <Container>
          <Row>
            <Col className='lg-6 xs-12'>
              <Box>
                <Heading>Calculator:</Heading>
              </Box>
            </Col>
          </Row>
          <Row>
            <Col className='lg-6 xs-12'>
              <Box>
                <p>
                  <span>Currency:</span>
                  <br />
                  <select
                    value={currency}
                    onChange={handleOnChangeCurrency.bind(this)}
                  >
                    {currencies.map((option) => (
                      <option key={option.label} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                </p>
                <p>
                  <span>Amount:</span>
                  <br />
                  <input
                    type='number'
                    name='amount'
                    id='amount'
                    min='0'
                    onChange={handleOnChangeAmount.bind(this)}
                    value={amount}
                  />
                </p>
                Current rate at 0.01 dollar
                <h2>
                  <span>You will receive exactly $VUFIS:</span>{' '}
                  {sendingAmount.toLocaleString()}
                </h2>
              </Box>
            </Col>
          </Row>
        </Container>
      </Wrapper>
    );
  }
}
