import React from 'react';
import { ThemeProvider } from 'styled-components';
import theme from 'assets/theme/theme';
import GlobalStyle from 'assets/theme';
import Calculator from 'sections/Calculator';
import Layout from '../components/layout';

// markup
const IndexPage = () => {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <Layout title="Home">
        <Calculator />
      </Layout>
    </ThemeProvider>
  );
};

export default IndexPage;
