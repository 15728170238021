import styled from 'styled-components';

const Wrapper = styled.div`
  position: relative;
  overflow: hidden;
  min-height: 670px;
  background: #f7f7f7;
  box-sizing: border-box;
  padding: 40px 0px 145px;
`;
export default Wrapper;
